<template>
  <Layout>
    <PageHeader :title="$t('fixed_assets.classes')" />
    <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'" class="row">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="
                        background-color: #2a3042 !important;
                        wdith: 250px !important;
                      "
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  @click="addFixedAssetClass()"
                  type="button"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-striped table-hover mb-0 mt-4"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">{{ $t("fixed_assets.id") }}</th>
                    <th scope="col">{{ $t("fixed_assets.label") }}</th>
                    <th scope="col">{{ $t("fixed_assets.label_en") }}</th>
                    <th scope="col">{{ $t("fixed_assets.subclasses_tot") }}</th>
                    <th scope="col">{{ $t("fixed_assets.created") }}</th>
                    <th scope="col">{{ $t("fixed_assets.updated") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cls, index) in fixedAssetsClasses"
                    :key="cls"
                    class=""
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ cls?.name }}</td>
                    <td>{{ cls?.name_en }}</td>
                    <td>{{ cls?.subclasses_tot }}</td>
                    <td>{{ cls?.created.split("T")[0] }}</td>
                    <td>{{ cls?.updated.split("T")[0] }}</td>
                    <td>
                      <a
                        style="margin-inline-end: 4px"
                        class="btn btn-primary btn-md"
                        @click="getFixedAssetSubClasses(cls)"
                        data-bs-toggle="modal"
                        data-bs-target="#subclassesModal"
                        role="button"
                        >{{ $t("popups.profile") }}</a
                      >
                      <a
                        style="margin-inline-end: 4px"
                        class="btn btn-primary btn-md"
                        @click="editFixedAssetClass(cls)"
                        data-bs-toggle="modal"
                        role="button"
                        >{{ $t("popups.edit") }}</a
                      >
                      <a
                        class="btn btn-danger btn-md"
                        href="javascript: void(0);"
                        role="button"
                        @click="deleteFixedAssetClass(cls)"
                        >{{ $t("popups.delete") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end  table -->

      <!--end-->
    </div>

    <!--Start SubClasses Modal-->
    <div
      class="modal fade"
      id="subclassesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{
                $i18n.locale == "en"
                  ? "Subclasses of " + cls.name_en
                  : "الفئات الفرعية ل" + cls.name
              }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6"></div>
                    <div
                      class="col-6"
                      style="display: flex; justify-content: flex-end"
                    >
                      <button
                        @click="addFixedAssetSubClass()"
                        type="button"
                        class="btn btn-light float-end mb-4"
                      >
                        <span
                          class="bx bxs-plus-circle float-end fa-2x"
                          style="color: #2a3042 !important"
                        ></span>
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      class="table table-striped table-hover mb-0 mt-4"
                    >
                      <thead>
                        <tr
                          class="text-light"
                          style="background-color: #2a3042 !important"
                        >
                          <th scope="col">{{ $t("fixed_assets.id") }}</th>
                          <th scope="col">{{ $t("fixed_assets.label") }}</th>
                          <th scope="col">{{ $t("fixed_assets.label_en") }}</th>
                          <th scope="col">{{ $t("fixed_assets.created") }}</th>
                          <th scope="col">{{ $t("fixed_assets.updated") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cls, index) in fixedAssetsSubClasses"
                          :key="cls"
                          class=""
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ cls?.name }}</td>
                          <td>{{ cls?.name_en }}</td>
                          <td>{{ cls?.created.split("T")[0] }}</td>
                          <td>{{ cls?.updated.split("T")[0] }}</td>
                          <td>
                            <a
                              style="margin-inline-end: 4px"
                              class="btn btn-primary btn-md"
                              @click="editFixedAssetSubClass(cls)"
                              data-bs-toggle="modal"
                              role="button"
                              >{{ $t("popups.edit") }}</a
                            >
                            <a
                              class="btn btn-danger btn-md"
                              href="javascript: void(0);"
                              role="button"
                              @click="deleteFixedAssetSubClass(cls)"
                              >{{ $t("popups.delete") }}</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End SubClasses Modal-->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      fixedAssetsClasses: [],
      fixedAssetsSubClasses: [],
      cls: {},
    };
  },
  methods: {
    // fixed assets class methods
    addFixedAssetClass() {
      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "fixed_assets.label" },
          { model: "name_en", type: "text", label: "fixed_assets.label_en" },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.branch_id = this.branch_id;
              this.http.post("fixed-assets/classes", obj).then(() => {
                this.getFixedAssetsClasses();
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editFixedAssetClass(cls) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "fixed_assets.label",
            value: cls.name,
          },
          {
            model: "name_en",
            type: "text",
            label: "fixed_assets.label_en",
            value: cls.name_en,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("fixed-assets/classes", cls.id, obj).then(() => {
                this.getFixedAssetsClasses();
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteFixedAssetClass(cls) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("fixed-assets/classes", cls.id).then(() => {
            this.getFixedAssetsClasses();
          });
        }
      });
    },
    getFixedAssetsClasses() {
      this.http.get("fixed-assets/classes").then((res) => {
        this.fixedAssetsClasses = res.data;
      });
    },
    getFixedAssetSubClasses(cls) {
      this.cls = cls;
      this.http.get("fixed-assets/subclasses/" + cls.id).then((res) => {
        this.fixedAssetsSubClasses = res.data;
      });
    },

    // fixed assets subclass methods
    addFixedAssetSubClass() {
      document.querySelectorAll(".btn-close").forEach((btn) => btn.click());
      setTimeout(() => {
        var data = {
          title: "popups.add",
          inputs: [
            { model: "name", type: "text", label: "fixed_assets.label" },
            { model: "name_en", type: "text", label: "fixed_assets.label_en" },
          ],
          buttons: [
            {
              text: "add",
              closer: true,
              color: "primary",
              handler: (obj) => {
                obj.branch_id = this.branch_id;
                this.http
                  .post("fixed-assets/subclasses/" + this.cls.id, obj)
                  .then(() => {
                    this.getFixedAssetsClasses();
                  });
              },
            },
            {
              text: "Cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      }, 100);
    },
    editFixedAssetSubClass(cls) {
      document.querySelectorAll(".btn-close").forEach((btn) => btn.click());
      setTimeout(() => {
        var data = {
          title: "popups.edit",
          inputs: [
            {
              model: "name",
              type: "text",
              label: "fixed_assets.label",
              value: cls.name,
            },
            {
              model: "name_en",
              type: "text",
              label: "fixed_assets.label_en",
              value: cls.name_en,
            },
          ],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                this.http
                  .put("fixed-assets/subclasses", cls.id, obj)
                  .then(() => {});
              },
            },
            {
              text: "Cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      }, 300);
    },
    deleteFixedAssetSubClass(cls) {
      document.querySelectorAll(".btn-close").forEach((btn) => btn.click());
      setTimeout(() => {
        var data = {
          title: "popups.delete",
          msg: "popups.delmessage",
        };
        this.popup.confirm(data).then((resp) => {
          if (resp) {
            this.http.delete("fixed-assets/subclasses", cls.id).then(() => {
              this.getFixedAssetsClasses();
            });
          }
        });
      }, 300);
    },
  },
  created() {
    this.getFixedAssetsClasses();
  },
};
</script>
